// 获取列表
const getListURL = `${API_CONFIG.butlerBaseURL}electronicPatrol/point`
// 导出列表
const exportListURL = `${API_CONFIG.butlerBaseURL}electronicPatrol/point/export`
// 删除巡查点
const delPointURL = `${API_CONFIG.butlerBaseURL}electronicPatrol/point/`
// 获取蓝牙编号
const getBlueToothNumURL = `${API_CONFIG.baseURL}smDeviceAction!queryBlueteeth.action`
// 获取蓝牙具体信息
const getBlueToothDetailURL = `${API_CONFIG.butlerBaseURL}electronicPatrol/bluetooth/`
// 获取分类下的巡更内容
const getContentFormCategoryURL = `${API_CONFIG.butlerBaseURL}electronicPatrol/content/simple/`
// 保存巡更内容
const savePointUrl = `${API_CONFIG.butlerBaseURL}electronicPatrol/point`
// 获取巡更内容详情
const getPointUrl = `${API_CONFIG.butlerBaseURL}electronicPatrol/point/`

// 获取视频搜索接口
const selectEagleEyeInfo = `${API_CONFIG.butlerBaseURL}eagleEye/selectEagleEyeInfo`
// 获取视频设备详情
const selectEagleEyedetail = `${API_CONFIG.butlerBaseURL}eagleEye/detail`

export {
  getListURL,
  exportListURL,
  delPointURL,
  getBlueToothNumURL,
  getBlueToothDetailURL,
  getContentFormCategoryURL,
  getPointUrl,
  savePointUrl,
  selectEagleEyeInfo,
  selectEagleEyedetail,
}
