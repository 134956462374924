<!--
 * @Author: xfj
 * @Date: 2019-08-16 16:11:19
 * @LastEditors: xfj
 * @LastEditTime: 2019-09-26 11:48:18
 * @Description: 巡更点管理
 -->
<template>
  <div class="patrolPointForm-container">
    <form-panel
      ref="formPanel"
      :form="form"
      v-bind="submitConfig"
      :submitUrl="submitUrl"
      submitText="保存"
      :submitBefore="submitBefore"
    >
      <col2-detail :span="24">
        <col2-block title="巡更点信息">
          <el-form-item
            label="巡更点名称"
            :rules="[
              { required: true, message: '请输入巡更点名称', trigger: 'blur' },
            ]"
            prop="name"
          >
            <v-input
              v-model="form.name"
              placeholder="请输入巡更点名称"
              :width="width"
            ></v-input>
          </el-form-item>
          <el-form-item
            label="所属项目"
            :rules="[
              { required: true, message: '请输入所属项目', trigger: 'blur' },
            ]"
            prop="communityId"
          >
            <v-select2
              v-model="form.communityId"
              placeholder="请输入所属项目"
              v-bind="communityParams"
              @onChange="communityChange"
            ></v-select2>
          </el-form-item>
          <el-form-item
            label="关联方式"
            :rules="[
              { required: true, message: '请选择关联方式', trigger: 'change' },
            ]"
            prop="patrolPointType"
          >
            <v-select
              v-model="form.patrolPointType"
              placeholder="请选择关联方式"
              :options="patrolPointTypeOps"
              @change="handleSelectChange"
              :width="width"
            ></v-select>
          </el-form-item>
          <el-form-item
            v-if="isShowBlueTooth"
            label="关联设备/标识"
            :rules="[
              {
                required: true,
                message: '请选择关联设备/标识',
                trigger: 'change',
              },
            ]"
            prop="bluetoothId"
          >
            <v-select2
              v-model="form.bluetoothId"
              v-bind="blueToothNumParams"
              :subjoin="subjoin"
              @onChange="blueToothChange"
              :width="width"
              ref="bluetoothSelect"
            ></v-select2>
          </el-form-item>
          <div v-show="bluetoothInfo.id">
            <el-form-item label="安装位置" v-if="form.patrolPointType != 3">
              <span>{{ installPosition }}</span>
            </el-form-item>
            <el-form-item label="设备状态" v-if="form.patrolPointType != 3">
              <span>{{ statusMap[bluetoothInfo.status] }}</span>
            </el-form-item>
            <el-form-item
              v-if="form.patrolPointType == 1 || form.patrolPointType == 4"
              label="所属项目"
            >
              <span>{{ bluetoothInfo.communityName }}</span>
            </el-form-item>
            <el-form-item
              v-if="form.patrolPointType == 1 || form.patrolPointType == 4"
              label="所属组团"
            >
              <span>{{ bluetoothInfo.area }}</span>
            </el-form-item>
          </div>
          <el-form-item
            label="巡更点分类"
            :rules="[
              { required: true, message: '请选择一级分类', trigger: 'change' },
            ]"
            prop="parentCategory"
            style="display: inline-block"
          >
            <v-select
              v-model="form.parentCategory"
              :options="firstOps"
              :width="width"
              @change="getSecondOpsFun"
            ></v-select>
          </el-form-item>
          &nbsp;&nbsp;-&nbsp;&nbsp;
          <el-form-item
            :rules="[
              { required: true, message: '请选择二级分类', trigger: 'change' },
            ]"
            prop="categoryId"
            class="category"
          >
            <v-select
              v-model="form.categoryId"
              :options="secondOps"
              :width="width"
              @change="secondCategoryChange"
            ></v-select>
          </el-form-item>
          <template v-if="form.patrolPointType == 3">
            <el-form-item label="经纬度" required>
              <el-row>
                <el-col :span="3">
                  <el-form-item
                    prop="longitude"
                    :rules="[
                      {
                        required: true,
                        message: '请选择经纬度',
                        trigger: 'blur',
                      },
                    ]"
                  >
                    <v-input
                      v-model="form.longitude"
                      placeholder="请选择经纬度"
                    ></v-input>
                  </el-form-item>
                </el-col>
                <el-col :span="3">
                  <el-form-item
                    prop="latitude"
                    :rules="[
                      {
                        required: true,
                        message: '请选择经纬度',
                        trigger: 'blur',
                      },
                    ]"
                    :width="width"
                  >
                    <v-input
                      v-model="form.latitude"
                      placeholder="请选择经纬度"
                    ></v-input>
                  </el-form-item>
                </el-col>
                <el-col :span="3">
                  <v-button text="地图标注" @click="showMap"></v-button>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item
              label="安装高度"
              :rules="[
                { required: true, message: '请输入安装高度', trigger: 'blur' },
              ]"
              prop="high"
            >
              <v-input-number
                v-model="form.high"
                :width="width"
                :controls="true"
                controls-position="right"
              />
              <span class="space_span">M</span>
            </el-form-item>
            <el-form-item label="是否开启巡更定位" prop="position">
              <el-switch
                v-model="form.position"
                :active-value="1"
                :inactive-value="2"
                active-color="#67C23A"
                @change="isPositionChange"
              />
              <el-form-item
                prop="radius"
                class="inline-block"
                :rules="[{ validator: radiusVakudate, trigger: 'blur' }]"
              >
                <v-input-number
                  v-model="form.radius"
                  label="根据LBS定位设置有效范围的半径"
                  :width="width"
                  :controls="true"
                  controls-position="right"
                />
                <span class="space_span">M</span>
              </el-form-item>
              <el-form-item label="是否开启巡更拍照" prop="camera">
                <el-switch
                  v-model="form.camera"
                  :active-value="1"
                  :inactive-value="2"
                  active-color="#67C23A"
                />
              </el-form-item>
            </el-form-item>
          </template>
          <div class="contentList">
            <el-form-item
              :label="index === 0 ? '巡更内容' : ''"
              v-for="(item, index) in form.contents"
              :key="index"
              :rules="[
                {
                  required: true,
                  message: '请输入巡更内容',
                  trigger: 'change',
                },
              ]"
              :prop="`contents.${index}.content`"
            >
              <v-input
                placeholder="请输入巡更内容"
                v-model="item.content"
                :class="index > 0 ? 'contengLine' : ''"
                :width="width"
                :maxlength="300"
              ></v-input>
              <v-button
                type="danger"
                text="删除"
                class="addBtn"
                @click="delContent(index)"
                v-show="index > 0"
              ></v-button>
              <v-button
                text="新增"
                @click="addContent"
                class="addBtn"
                v-show="index === 0"
              ></v-button>
            </el-form-item>
          </div>
        </col2-block>
      </col2-detail>
    </form-panel>
    <v-map
      :selectedLng="Number(form.longitude)"
      :selectedLat="Number(form.latitude)"
      :showMapDialog.sync="showMapDialog"
      @setLngAndLat="setLngAndLat"
    ></v-map>
  </div>
</template>

<script>
import {
  getBlueToothNumURL,
  selectEagleEyeInfo,
  getContentFormCategoryURL,
  getPointUrl,
  savePointUrl,
} from "./api";
import { getFirstOpts, getSecondOps, communityParams } from "./../map";
import { vMap } from "components/control";
import { statusMap, patrolPointTypeOps } from "./map";
import {
  blueToothUrlMap,
  installPositionStrMap,
  blueToothDeviceStrMap,
  blueToothNumParamsMap,
} from "./blueToothMap";
import { Col2Block, Col2Detail } from "components/bussiness";
import { Switch } from "element-ui";
export default {
  name: "patrolPointForm",
  components: {
    Col2Block,
    Col2Detail,
    vMap,
    "el-switch": Switch,
  },
  computed: {
    subjoin() {
      let obj = {
        communityId: this.form.communityId,
      };
      if (this.form.patrolPointType == 1) {
        obj.type = 1;
      }
      if (this.form.patrolPointType == 4) {
        obj.type = 2;
      }
      return obj;
    },
    videoPermission() {
      return this.$store.getters.getPermission("videoControl");
    },
    QRCodePermission() {
      return this.$store.getters.getPermission("QRCodeControl");
    },
    NFCPermission() {
      return this.$store.getters.getPermission("NFCControl");
    },
    patrolPointTypeOps() {
      return patrolPointTypeOps.call(this);
    },
    blueToothNumParams() {
      return blueToothNumParamsMap[this.form.patrolPointType];
    },
    bluetoothInfo() {
      let bluetoothInfo = this.blueToothCatch[this.form.patrolPointType] || {};
      console.log("--bluetoothInfo", bluetoothInfo);
      let key = blueToothDeviceStrMap[this.form.patrolPointType];
      this.$nextTick(() => {
        if (this.$refs.bluetoothSelect) {
          if (bluetoothInfo.id) {
            this.form.bluetoothId = bluetoothInfo.id;
            this.$refs.bluetoothSelect.defaultBackText = bluetoothInfo[key];
          } else {
            this.$refs.bluetoothSelect.clearValue();
          }
        }
      });
      return bluetoothInfo;
    },
    installPosition() {
      let data = this.bluetoothInfo;
      let key = installPositionStrMap[this.form.patrolPointType] || "";
      return data[key];
    },
    // 没有项目和巡更点为二维码时不展示
    isShowBlueTooth() {
      if (!this.form.communityId) {
        return false;
      }
      if (this.form.patrolPointType === 3) {
        this.communityId = this.form.communityId;
        return false;
      }
      return true;
    },
  },
  data() {
    return {
      width: 200,
      submitUrl: savePointUrl,
      submitConfig: {
        submitContentType: "application/x-www-form-urlencoded;charset=UTF-8",
        submitMethod: this.$route.query.id ? "put" : "post",
      },
      updateForm: {
        id: undefined,
        name: undefined,
        categoryId: undefined,
        communityId: undefined,
        longitude: undefined,
        latitude: undefined,
        high: undefined,
        bluetoothId: undefined,
        position: undefined,
        camera: undefined,
        radius: undefined,
      },
      form: {
        id: undefined,
        name: "",
        parentCategory: undefined,
        categoryId: undefined,
        communityId: undefined,
        longitude: undefined,
        latitude: undefined,
        high: undefined,
        bluetoothId: "",
        patrolPointType: 2,
        bluetoothInfo: {
          id: undefined,
        },
        contents: [{ content: "" }],
        position: undefined,
        camera: undefined,
        radius: undefined,
      },
      communityId: "",
      communityParams: communityParams,
      firstOps: [],
      secondOps: [],
      statusMap: statusMap,
      isInit: false,
      showMapDialog: false,
      blueToothCatch: {}, // 缓存对应类型上次修改的数据，切换时展示
    };
  },
  mounted() {
    if (this.$route.query.id) {
      this.form.patrolPointType = this.$route.query.type;
      this.getDetailData(this.$route.query.id);
    } else {
      this.form.patrolPointType = this.$route.query.type || 1;
    }
  },
  methods: {
    handleSelectChange() {
      this.form.bluetoothInfo = {};
      this.form.bluetoothId = "";
      // 清空选项数据
      if (this.$refs.bluetoothSelect) {
        this.$refs.bluetoothSelect.clearValue();
      }
    },
    // 获取二级分类
    getSecondOpsFun(value) {
      this.secondOps = [];
      //重置二级分类选择值
      if (!this.$route.query.id || this.isInit) {
        this.form.categoryId = undefined;
      }
      let _this_ = this;
      getSecondOps(value, this.secondOps, function () {
        if (_this_.secondOps.length === 0) {
          _this_.form.categoryId = undefined;
        }
      });
    },
    // 二级分类回调查询内容
    secondCategoryChange(value) {
      let _this_ = this;
      if (value) {
        this.$axios.get(getContentFormCategoryURL + value).then((res) => {
          if (res.status === 100) {
            let rdata = res.data;

            console.log("_this_.isInit", _this_.isInit);
            // 编辑的时候 将详情的数据赋值给巡更内容，change的时候拿接口返回的值
            if (!this.$route.query.id || _this_.isInit) {
              if (rdata && rdata.length) {
                _this_.form.contents = rdata.map((item) => {
                  let obj = {};
                  obj.content = item;
                  return obj;
                });
              } else {
                _this_.form.contents = [{ content: "" }];
              }
            } else {
              _this_.isInit = !_this_.isInit;
            }
          }
        });
      } else {
        _this_.form.contents = [{ content: "" }];
      }
    },
    // 项目回调
    communityChange() {
      // 重置缓存
      this.blueToothCatch = {};
      // 清空选项数据
      if (this.$refs.bluetoothSelect) {
        this.$refs.bluetoothSelect.clearValue();
      }
    },
    // 设备编号回调
    blueToothChange() {
      let patrolPointType = this.form.patrolPointType;
      let urlFunction =
        blueToothUrlMap[patrolPointType] ||
        function () {
          return "";
        };
      let url = urlFunction.call(this);
      if (!url || !this.form.bluetoothId) {
        this.form.bluetoothInfo = {};
        return;
      }
      this.$axios.get(url).then((res) => {
        if (res.status === 100) {
          let rdata = res.data;
          if (rdata) {
            this.$set(this.blueToothCatch, this.form.patrolPointType, rdata);
            this.form.bluetoothInfo = Object.assign({}, rdata);
            this.communityId = rdata.communityId;
          }
        }
      });
    },
    // 添加巡更内容
    addContent() {
      this.form.contents.push(
        { content: "" },
        { content: "" },
        { content: "" }
      );
    },
    // 删除巡更内容
    delContent(index) {
      this.form.contents.splice(index, 1);
    },
    // 获取详情数据
    async getDetailData(id) {
      let res = await this.$axios.get(getPointUrl + id);
      if (res.status === 100) {
        const { data = {} } = res;
        let { contents } = data;
        Object.keys(this.updateForm).forEach((key) => {
          this.$set(this.form, key, data[key]);
        });
        this.$set(this.form, "parentCategory", data.parentId);
        // 初始化需要一个空数据否则不展示的
        if (!contents.length) {
          contents.push(" ");
        }
        let contentList = contents.map((item) => {
          return {
            content: item,
          };
        });
        this.$set(this.form, "contents", contentList);
      }
    },
    // 保存前数据处理
    submitBefore(data) {
      let contentsData = "";
      if (data.contents.length > 0) {
        data.contents.forEach(function (e) {
          contentsData += e.content + ",";
        });
      }
      data.contents = contentsData.toString();
      data.dataObject = contentsData;
      delete data.parentCategory;
      delete data.bluetoothInfo;
      console.log(data);
      return true;
    },
    showMap() {
      this.showMapDialog = true;
    },
    setLngAndLat(l) {
      if (!l.lng || !l.lat) {
        return false;
      }
      this.form.longitude = l.lng;
      this.form.latitude = l.lat;
    },
    radiusVakudate(rule, value, callback) {
      if (this.form.position == 1 && !value) {
        callback(new Error("请输入定位有效范围的半径"));
        return;
      }
      callback();
    },
    isPositionChange(value) {
      if (value != 1) {
        this.$set(this.form, "radius", 0);
      }
    },
  },
  watch: {
    communityId: async function (newVal, oldVal) {
      console.log("--newVal", newVal);
      this.firstOps = await getFirstOpts({ communityId: newVal });
      if (this.firstOps.length > 0) {
        if (!this.$route.query.id) {
          // 如果是新增，给一级分类默认值
          this.form.parentCategory = this.firstOps[0].value;
        }
      } else {
        this.form.parentCategory = undefined;
        this.$alert("请先添加巡更分类");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.patrolPointForm-container {
  .contentList {
    .addBtn {
      margin-left: 20px;
    }
  }
  .category {
    display: inline-block;
    ::v-deep .el-form-item__content {
      margin-left: 0 !important;
    }
  }
  .space_span {
    padding: 0 20px;
  }
}
.inline-block {
  margin-left: 10px;
  display: inline-block;
}
</style>
