import { generateMapByOpts, mapHelper } from 'common/utils'
// 获取设备具体信息  获取视频设备详情  获取设备编号 获取视频搜索接口
import { getBlueToothDetailURL, selectEagleEyedetail, getBlueToothNumURL, selectEagleEyeInfo } from './api'
// patrolPointType 1蓝牙 2视频 3二维码 4NFC
// ${this.form.patrolPointType == 1 ? getBlueToothDetailURL : selectEagleEyedetail + '?cameraId='}${this.form.bluetoothId}`
// 设备编号回调请求地址 二维码没有
const blueToothUrlMap = {
  1: function () {
    return getBlueToothDetailURL + `${this.form.bluetoothId}`
  },
  2: function () {
    return selectEagleEyedetail + `?cameraId=${this.form.bluetoothId}`
  },
  4: function () {
    return getBlueToothDetailURL + `${this.form.bluetoothId}`
  },
}

// 安装位置再数据中的key 二维码没有
const installPositionStrMap = {
  1: 'location',
  2: 'cameraName',
  3: '',
  4: 'location'
}

// 设备名在数据中的key  二维码没有
const blueToothDeviceStrMap = {
  1: 'serialnum',
  2: 'cameraName',
  3: '',
  4: 'serialnum'
}

// 请求关联设备配置 二维码没有
const blueToothNumParamsMap = {
  1: {
    placeholder: '选择蓝牙',
    searchUrl: getBlueToothNumURL,
    request: {
      text: 'serialNum',
      value: 'id'
    },
    response: {
      text: 'serialNum',
      value: 'id'
    }
  },
  2: {
    placeholder: '选择设备',
    searchUrl: selectEagleEyeInfo,
  },
  4: {
    placeholder: '选择NFC',
    searchUrl: getBlueToothNumURL,
    request: {
      text: 'serialNum',
      value: 'id'
    },
    response: {
      text: 'serialNum',
      value: 'id'
    }
  }
}



export {
  blueToothUrlMap,
  installPositionStrMap,
  blueToothDeviceStrMap,
  blueToothNumParamsMap
}