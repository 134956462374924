var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "patrolPointForm-container" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: {
              form: _vm.form,
              submitUrl: _vm.submitUrl,
              submitText: "保存",
              submitBefore: _vm.submitBefore,
            },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            { attrs: { span: 24 } },
            [
              _c(
                "col2-block",
                { attrs: { title: "巡更点信息" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "巡更点名称",
                        rules: [
                          {
                            required: true,
                            message: "请输入巡更点名称",
                            trigger: "blur",
                          },
                        ],
                        prop: "name",
                      },
                    },
                    [
                      _c("v-input", {
                        attrs: {
                          placeholder: "请输入巡更点名称",
                          width: _vm.width,
                        },
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "所属项目",
                        rules: [
                          {
                            required: true,
                            message: "请输入所属项目",
                            trigger: "blur",
                          },
                        ],
                        prop: "communityId",
                      },
                    },
                    [
                      _c(
                        "v-select2",
                        _vm._b(
                          {
                            attrs: { placeholder: "请输入所属项目" },
                            on: { onChange: _vm.communityChange },
                            model: {
                              value: _vm.form.communityId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "communityId", $$v)
                              },
                              expression: "form.communityId",
                            },
                          },
                          "v-select2",
                          _vm.communityParams,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "关联方式",
                        rules: [
                          {
                            required: true,
                            message: "请选择关联方式",
                            trigger: "change",
                          },
                        ],
                        prop: "patrolPointType",
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: {
                          placeholder: "请选择关联方式",
                          options: _vm.patrolPointTypeOps,
                          width: _vm.width,
                        },
                        on: { change: _vm.handleSelectChange },
                        model: {
                          value: _vm.form.patrolPointType,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "patrolPointType", $$v)
                          },
                          expression: "form.patrolPointType",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.isShowBlueTooth
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "关联设备/标识",
                            rules: [
                              {
                                required: true,
                                message: "请选择关联设备/标识",
                                trigger: "change",
                              },
                            ],
                            prop: "bluetoothId",
                          },
                        },
                        [
                          _c(
                            "v-select2",
                            _vm._b(
                              {
                                ref: "bluetoothSelect",
                                attrs: {
                                  subjoin: _vm.subjoin,
                                  width: _vm.width,
                                },
                                on: { onChange: _vm.blueToothChange },
                                model: {
                                  value: _vm.form.bluetoothId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "bluetoothId", $$v)
                                  },
                                  expression: "form.bluetoothId",
                                },
                              },
                              "v-select2",
                              _vm.blueToothNumParams,
                              false
                            )
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.bluetoothInfo.id,
                          expression: "bluetoothInfo.id",
                        },
                      ],
                    },
                    [
                      _vm.form.patrolPointType != 3
                        ? _c("el-form-item", { attrs: { label: "安装位置" } }, [
                            _c("span", [_vm._v(_vm._s(_vm.installPosition))]),
                          ])
                        : _vm._e(),
                      _vm.form.patrolPointType != 3
                        ? _c("el-form-item", { attrs: { label: "设备状态" } }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.statusMap[_vm.bluetoothInfo.status])
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm.form.patrolPointType == 1 ||
                      _vm.form.patrolPointType == 4
                        ? _c("el-form-item", { attrs: { label: "所属项目" } }, [
                            _c("span", [
                              _vm._v(_vm._s(_vm.bluetoothInfo.communityName)),
                            ]),
                          ])
                        : _vm._e(),
                      _vm.form.patrolPointType == 1 ||
                      _vm.form.patrolPointType == 4
                        ? _c("el-form-item", { attrs: { label: "所属组团" } }, [
                            _c("span", [
                              _vm._v(_vm._s(_vm.bluetoothInfo.area)),
                            ]),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { display: "inline-block" },
                      attrs: {
                        label: "巡更点分类",
                        rules: [
                          {
                            required: true,
                            message: "请选择一级分类",
                            trigger: "change",
                          },
                        ],
                        prop: "parentCategory",
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.firstOps, width: _vm.width },
                        on: { change: _vm.getSecondOpsFun },
                        model: {
                          value: _vm.form.parentCategory,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "parentCategory", $$v)
                          },
                          expression: "form.parentCategory",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v("\n          -  \n        "),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "category",
                      attrs: {
                        rules: [
                          {
                            required: true,
                            message: "请选择二级分类",
                            trigger: "change",
                          },
                        ],
                        prop: "categoryId",
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.secondOps, width: _vm.width },
                        on: { change: _vm.secondCategoryChange },
                        model: {
                          value: _vm.form.categoryId,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "categoryId", $$v)
                          },
                          expression: "form.categoryId",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.form.patrolPointType == 3
                    ? [
                        _c(
                          "el-form-item",
                          { attrs: { label: "经纬度", required: "" } },
                          [
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 3 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop: "longitude",
                                          rules: [
                                            {
                                              required: true,
                                              message: "请选择经纬度",
                                              trigger: "blur",
                                            },
                                          ],
                                        },
                                      },
                                      [
                                        _c("v-input", {
                                          attrs: {
                                            placeholder: "请选择经纬度",
                                          },
                                          model: {
                                            value: _vm.form.longitude,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "longitude",
                                                $$v
                                              )
                                            },
                                            expression: "form.longitude",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 3 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop: "latitude",
                                          rules: [
                                            {
                                              required: true,
                                              message: "请选择经纬度",
                                              trigger: "blur",
                                            },
                                          ],
                                          width: _vm.width,
                                        },
                                      },
                                      [
                                        _c("v-input", {
                                          attrs: {
                                            placeholder: "请选择经纬度",
                                          },
                                          model: {
                                            value: _vm.form.latitude,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "latitude",
                                                $$v
                                              )
                                            },
                                            expression: "form.latitude",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 3 } },
                                  [
                                    _c("v-button", {
                                      attrs: { text: "地图标注" },
                                      on: { click: _vm.showMap },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "安装高度",
                              rules: [
                                {
                                  required: true,
                                  message: "请输入安装高度",
                                  trigger: "blur",
                                },
                              ],
                              prop: "high",
                            },
                          },
                          [
                            _c("v-input-number", {
                              attrs: {
                                width: _vm.width,
                                controls: true,
                                "controls-position": "right",
                              },
                              model: {
                                value: _vm.form.high,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "high", $$v)
                                },
                                expression: "form.high",
                              },
                            }),
                            _c("span", { staticClass: "space_span" }, [
                              _vm._v("M"),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "是否开启巡更定位",
                              prop: "position",
                            },
                          },
                          [
                            _c("el-switch", {
                              attrs: {
                                "active-value": 1,
                                "inactive-value": 2,
                                "active-color": "#67C23A",
                              },
                              on: { change: _vm.isPositionChange },
                              model: {
                                value: _vm.form.position,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "position", $$v)
                                },
                                expression: "form.position",
                              },
                            }),
                            _c(
                              "el-form-item",
                              {
                                staticClass: "inline-block",
                                attrs: {
                                  prop: "radius",
                                  rules: [
                                    {
                                      validator: _vm.radiusVakudate,
                                      trigger: "blur",
                                    },
                                  ],
                                },
                              },
                              [
                                _c("v-input-number", {
                                  attrs: {
                                    label: "根据LBS定位设置有效范围的半径",
                                    width: _vm.width,
                                    controls: true,
                                    "controls-position": "right",
                                  },
                                  model: {
                                    value: _vm.form.radius,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "radius", $$v)
                                    },
                                    expression: "form.radius",
                                  },
                                }),
                                _c("span", { staticClass: "space_span" }, [
                                  _vm._v("M"),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "是否开启巡更拍照",
                                  prop: "camera",
                                },
                              },
                              [
                                _c("el-switch", {
                                  attrs: {
                                    "active-value": 1,
                                    "inactive-value": 2,
                                    "active-color": "#67C23A",
                                  },
                                  model: {
                                    value: _vm.form.camera,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "camera", $$v)
                                    },
                                    expression: "form.camera",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "contentList" },
                    _vm._l(_vm.form.contents, function (item, index) {
                      return _c(
                        "el-form-item",
                        {
                          key: index,
                          attrs: {
                            label: index === 0 ? "巡更内容" : "",
                            rules: [
                              {
                                required: true,
                                message: "请输入巡更内容",
                                trigger: "change",
                              },
                            ],
                            prop: `contents.${index}.content`,
                          },
                        },
                        [
                          _c("v-input", {
                            class: index > 0 ? "contengLine" : "",
                            attrs: {
                              placeholder: "请输入巡更内容",
                              width: _vm.width,
                              maxlength: 300,
                            },
                            model: {
                              value: item.content,
                              callback: function ($$v) {
                                _vm.$set(item, "content", $$v)
                              },
                              expression: "item.content",
                            },
                          }),
                          _c("v-button", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: index > 0,
                                expression: "index > 0",
                              },
                            ],
                            staticClass: "addBtn",
                            attrs: { type: "danger", text: "删除" },
                            on: {
                              click: function ($event) {
                                return _vm.delContent(index)
                              },
                            },
                          }),
                          _c("v-button", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: index === 0,
                                expression: "index === 0",
                              },
                            ],
                            staticClass: "addBtn",
                            attrs: { text: "新增" },
                            on: { click: _vm.addContent },
                          }),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-map", {
        attrs: {
          selectedLng: Number(_vm.form.longitude),
          selectedLat: Number(_vm.form.latitude),
          showMapDialog: _vm.showMapDialog,
        },
        on: {
          "update:showMapDialog": function ($event) {
            _vm.showMapDialog = $event
          },
          "update:show-map-dialog": function ($event) {
            _vm.showMapDialog = $event
          },
          setLngAndLat: _vm.setLngAndLat,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }